<template>
  <div>
    <el-row>
      <h3>グラフ描画範囲</h3>
    </el-row>
    <el-row>
      <div class="block">
        <el-slider
          v-model="value"
          range
          show-stops
          :max="allLabels.length">
        </el-slider>
      </div>
    </el-row>
    <el-row>
      <h3>評価推移</h3>
    </el-row>
    <el-row>
      <div class='chart'>
        <chart-review
          :styles="myStyles" 
          :chart-data="reviewData"
          :options="options"></chart-review>
      </div>
    </el-row>
    <el-row>
      <h3>感情推移</h3>
    </el-row>
    <el-row>
      <h4>大「楽しい、面白い」ー「哀しい、痛々しい」小</h4>
    </el-row>
        <el-row>
      <div class='chart'>
        <chart-review
          :styles="myStyles" 
          :chart-data="kiData"
          :options="options"></chart-review>
      </div>
    </el-row>
    <el-row>
      <h4>大「怒る、腹立たしい」ー「怖い、不安」小</h4>
    </el-row>
        <el-row>
      <div class='chart'>
        <chart-review
          :styles="myStyles" 
          :chart-data="doData"
          :options="options"></chart-review>
      </div>
    </el-row>
    <el-row>
      <h4>大「恋しい、愛しい」ー「憎い、暗い、辛い」小</h4>
    </el-row>
        <el-row>
      <div class='chart'>
        <chart-review
          :styles="myStyles" 
          :chart-data="koData"
          :options="options"></chart-review>
      </div>
    </el-row>
    <el-row>
      <h4>大「安心、のんびり」ー「驚く」小</h4>
    </el-row>
        <el-row>
      <div class='chart'>
        <chart-review
          :styles="myStyles" 
          :chart-data="anData"
          :options="options"></chart-review>
      </div>
    </el-row>
  </div>
</template>
<script>
import ChartReview from './ChartReview.vue'

export default {
  data() {
    return {
      height: 300,
      displays: 10,
      endChartData: null,
      reviewData: null,
      kiData: null,
      doData: null,
      koData: null,
      anData: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 1,
                max: 5 
              }
            }
          ]
        }
      },
      allReviewData: [],
      allKiData: [],
      allDoData: [],
      allKoData: [],
      allAnData: [],
      allLabels: [],
      tableDataMod: [],
      value: [0, 0],
    }
  },
  components: {
    ChartReview
  },
  props: ['tableData'],
  methods: {
    getDataFrame() {
      let dataFrame = {
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [],
            fill: false,
          }
        ]
      }
      return dataFrame
    },
    selectData () {
      // propsからグラフ用のデータを切り出し
      this.allReviewData = []
      this.allLabels = []

      for (const item of this.tableData.episodeData) {
        this.allReviewData.push(item.review)
        this.allKiData.push(item.m_ki)
        this.allDoData.push(item.m_do)
        this.allKoData.push(item.m_ko)
        this.allAnData.push(item.m_an)
        this.allLabels.push(item.episode+': '+item.title)
      }
    },
    createChartData (dataRange) {
      // グラフデータ作成
      let reviewData = this.getDataFrame()
      reviewData.labels = this.allLabels.slice(dataRange[0], dataRange[1])
      reviewData.datasets[0].data = this.allReviewData.slice(dataRange[0], dataRange[1])
      this.reviewData = reviewData

      let kiData = this.getDataFrame()
      kiData.labels = this.allLabels.slice(dataRange[0], dataRange[1])
      kiData.datasets[0].data = this.allKiData.slice(dataRange[0], dataRange[1])
      this.kiData = kiData

      let doData = this.getDataFrame()
      doData.labels = this.allLabels.slice(dataRange[0], dataRange[1])
      doData.datasets[0].data = this.allDoData.slice(dataRange[0], dataRange[1])
      this.doData = doData

      let koData = this.getDataFrame()
      koData.labels = this.allLabels.slice(dataRange[0], dataRange[1])
      koData.datasets[0].data = this.allKoData.slice(dataRange[0], dataRange[1])
      this.koData = koData

      let anData = this.getDataFrame()
      anData.labels = this.allLabels.slice(dataRange[0], dataRange[1])
      anData.datasets[0].data = this.allAnData.slice(dataRange[0], dataRange[1])
      this.anData = anData
      // console.log(this.koData)
    },
  },
  computed:{
    myStyles(){
      return{
        height: `${this.height}px`,
        position:'relative'
      }
    }
  },
  created () {
    this.endChartData = this.tableData.episodeData.length
    this.value = [0, this.endChartData]
    this.selectData()
    this.createChartData(this.value)
  },
  watch: {
    // sliderの値と連動してグラフデータを変更
    value: function (newVal) {
      // console.log(newVal)
      // console.log(this.allLabels.slice(newVal[0], newVal[1]))
      this.createChartData(newVal)
    }
  }
}
</script>

<style>
.chart {
  max-width: 100%;
  margin: auto;
}
</style>
