<template>
  <div>
    <el-row>
      <h3>エピソード一覧</h3>
    </el-row>
    <el-table
      :data="pagedTableData"
      style="width: 100%">
      <el-table-column
        prop="episode"
        label="話"
        width="80">
      </el-table-column>
      <el-table-column
        prop="title"
        label="タイトル"
        width="180">
      </el-table-column>
      <el-table-column
        prop="volume"
        label="巻数"
        width="80">
      </el-table-column>
      <el-table-column
        prop="review"
        label="評価"
        width="140">
        <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.review"
            disabled
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.review}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemnum"
        label="件数"
        width="80">
      </el-table-column>
      <el-table-column
        prop="m_ki"
        label="喜-哀"
        width="140">
          <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.m_ki"
            disabled
            :colors="colors"
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.m_ki_str}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="m_do"
        label="怒-怖"
        width="140">
        <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.m_do"
            disabled
            :colors="colors"
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.m_do_str}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="m_ko"
        label="好-嫌"
        width="140">
        <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.m_ko"
            disabled
            :colors="colors"
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.m_ko_str}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="m_an"
        label="安-驚"
        width="140">
        <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.m_an"
            disabled
            :colors="colors"
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.m_an_str}}
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="tableData.episodeData.length"
      @current-change="setPage">
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
     colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      page: 1,
      pageSize: 50,
    }
  },
  props: ['tableData'],
  computed: {
    pagedTableData() {
      return this.tableData.episodeData.slice(
        this.pageSize * this.page - this.pageSize, this.pageSize * this.page
      )
    }
  },
  methods: {
    getDataFrame() {
      let dataFrame = {
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [],
            fill: false,
          }
        ]
      }
      return dataFrame
    },
    setPage (val) {
      this.page = val
    }
  },
  created () {
  },
}
</script>

<style>
.chart {
  max-width: 100%;
  margin: auto;
}
</style>
