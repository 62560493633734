<template>
  <div>
    <el-row>
      <h3>単行本一覧</h3>
    </el-row>
    <el-table
      :data="tableData.volumeData"
      style="width: 100%">
      <el-table-column
        prop="volume"
        label="巻数"
        width="120">
      </el-table-column>
      <el-table-column
        prop="review"
        label="評価"
        width="140">
        <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.review"
            disabled
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.review}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemnum"
        label="件数"
        width="120">
      </el-table-column>
      <el-table-column
        prop="overview"
        label="概要"
        width="600">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: ['tableData'],
  methods: {
    test () {
      console.log(this.tableData)
    }
  }
}
</script>
