import Vue from 'vue'
import VueRouter from 'vue-router'
import TitleList from '../views/TitleList.vue'
import Details from '../views/Details.vue'

// Sample
import HighChartsSample from '../views/HighChartsSample.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TitleList',
    component: TitleList
  },
  {
    path: '/details/:id',
    name: 'Details',
    component: Details
  },
  {
    path: '/highcharts',
    name: 'Highcharts',
    component: HighChartsSample
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
