<template>
  <el-container>
    <el-header>
      <el-row>
        <el-col :span="8">
          <img alt="logo" height="60px" src="./assets/logo.svg">
        </el-col>
        <!-- <el-col :span="4">
          <h1 id="title">俺の本棚</h1>
        </el-col> -->
        <el-col :span="12">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1"><router-link to="/">タイトル一覧</router-link></el-menu-item>
            <!-- <el-menu-item index="2"><a ref="http://turedure-blog.com/blog">ブログ</a></el-menu-item> -->
          </el-menu>
        </el-col>
        <i class="el-icon-s-promotion" />
        <a href="http://turedure-blog.com/blog/" style="font-size: 14px">ブログ</a>
      </el-row>
    </el-header>
    <el-main>
      {{ msg }}
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
      msg: null,
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.title {
  margin-block-start: 0;
  margin-block-end: 0;
}

.el-header,
.el-footer {
  background-color: white;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}
</style>
