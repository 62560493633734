<template>
  <div>
      <el-row>
        <h2>{{comics_id}}</h2>
      </el-row>
      <el-row>
        <el-button @click="currentComponent = 'VolumeList'">巻一覧</el-button>
        <el-button @click="currentComponent = 'EpisodeList'">エピソード一覧</el-button>
        <el-button @click="currentComponent = 'EmotionGraph'">評価グラフ</el-button>
      </el-row>
      <component
        v-bind:is="currentComponent"
        v-bind:tableData="tableData"
      ></component>
      <el-row>
        <el-button><router-link to="/">戻る</router-link></el-button>
      </el-row>
  </div>
</template>

<script>
import VolumeList from '../components/VolumeList.vue'
import EpisodeList from '../components/EpisodeList.vue'
import EmotionGraph from '../components/EmotionGraph.vue'

export default {
    components: {
      VolumeList,
      EpisodeList,
      EmotionGraph,
    },
    data() {
      return {
        currentComponent: 'VolumeList',
        comics_id: '',
        title: 'ワンピース',
        tableData: {
          volumeData: [],
          episodeData: [],
        }
      }
    },
    mounted () {
      this.comics_id = this.$route.params.id
      // console.log(this.comics_id)
      this.axios
        .get(
          "/get-volumes",
          {params : {
            title: this.comics_id
          }}
        )
        .then((response) => (this.tableData.volumeData = response.data));
      this.axios
        .get(
          "/get-episodes",
          {params : {
            title: this.comics_id
          }}
        )
        .then((response) => (this.tableData.episodeData = response.data));
    }
  }
</script>
