<template>
  <div>
     <el-table
      :data="tableData"
      style="width: 100%"
      id="comic-list">
      <el-table-column
        label="分析"
        width="120">
        <template #default="scope">
          <router-link 
            :to="{name:'Details',params:{id:scope.row.title}}"
            tag="button">詳細</router-link>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        width="120">
        <template #default="scope">
          <a target="_blank"  :href=scope.row.url1><img border="0" :src=scope.row.url2 ></a>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="作品名"
        width="120">
      </el-table-column>
      <el-table-column
        prop="review"
        label="評価"
        width="140">
        <template #default="scope">
          <el-row>
          <el-rate
            v-model="scope.row.review"
            disabled
            text-color="#ff9900">
          </el-rate>
          </el-row>
          <el-row>
            {{scope.row.review}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemnum"
        label="件数"
        width="120">
      </el-table-column>
      <el-table-column
        prop="publisher"
        label="出版社"
        width="120">
      </el-table-column>
      <el-table-column
        prop="magazine"
        label="掲載誌"
        width="120">
      </el-table-column>
      <el-table-column
        prop="label"
        label="レーベル"
        width="120">
      </el-table-column>
      <el-table-column
        prop="author"
        label="作者"
        width="120">
      </el-table-column>
      <el-table-column
        prop="overview"
        label="概要"
        width="400">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    }
  },
  mounted() {
    this.axios
      .get(
        "/get-comics"
      )
      .then((response) => (this.tableData = response.data));
  },
  methods: {
  }
}
</script>

<style>
</style>
